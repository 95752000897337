
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'BaseToggleButtons',
  emits: ['select-button'],
  props: {
    settings: {
      type: Array,
      required: true
    },
    defaultSelected: {
      type: Object,
      required: false
    },
    activeBorderColor: {
      type: String,
      default: 'default',
      validator(value: string) {
        return [
          'default',
        ].indexOf(value) !== -1
      },
    }
  },
  data: () => ({
    selectedButton: null as any
  }),
  methods: {
    selectButton(settingButton: any) {
      this.selectedButton = settingButton
      this.$emit('select-button', settingButton)
    }
  },
  computed: {
    classList() {
      return (settingButton: any) => ([
        this.isButtonSelected(settingButton) &&
          [
            'base-toggle-buttons__button_active',
            settingButton && settingButton.activeBorderColor && `base-toggle-buttons__button_border-color_${settingButton.activeBorderColor}`,
            settingButton && settingButton.activeBackgroundColor && `base-toggle-buttons__button_background-color_${settingButton.activeBackgroundColor}`,
            settingButton && settingButton.activeColorFont && `base-toggle-buttons__button_font-color_${settingButton.activeColorFont}`
          ]
      ])
    },
    isButtonSelected(): any {
      return (settingButton: any) => {
        return settingButton.name === this.selectedButton?.name
      }
    }
  },
  mounted() {
    if (this.defaultSelected) {
      this.selectedButton = this.defaultSelected
    }
  }
})
